import React, { Component } from 'react';
import {firestore} from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import moment from 'moment';
import 'moment-timezone';
import { Redirect } from 'react-router-dom';
import { publicarVendido } from './../../services/VendidoService';
import { baixarVendido } from '../../services/VeiculoService';
import Modal from 'react-modal';

Modal.setAppElement('#root');

class DashVendidos extends Component {

    constructor (props){
        super(props);

        this.state = {
            vendidos: [],
            countVendidos: 0,
            modalShow: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.publicarInstagramStories = this.publicarInstagramStories.bind(this);
        this.publicarInstagramFeed    = this.publicarInstagramFeed.bind(this);
        this.publicarFacebookFeed     = this.publicarFacebookFeed.bind(this);
        this.validaVendido            = this.validaVendido.bind(this);
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("dashboard").doc("veiculos").collection("vendidos").orderBy("created_at", "desc")
        .get()
        .then((querySnapshot) => {

            if(this.mounted) {
                this.setState({
                    vendidos: [],
                    countVendidos: 0
                });
            }

            querySnapshot.forEach(veiculo_dash => {

                var doc_dash = veiculo_dash.data();
                doc_dash.id  = veiculo_dash.id;

                firestore.collection('revenda').doc(doc_dash.idRevenda).collection('integracoes').get()
                .then((snapIntegracaoRevenda) => {

                    var statusInstagram = false;
                    var statusFacebook  = false;
                    snapIntegracaoRevenda.forEach(integracao_revenda_dash => {
                        if(integracao_revenda_dash.id === "instagram" && integracao_revenda_dash.data().status === true){
                            statusInstagram = integracao_revenda_dash.data().status;
                        }
                        if(integracao_revenda_dash.id === "facebook" && integracao_revenda_dash.data().status === true){
                            statusFacebook = integracao_revenda_dash.data().status;
                        }
                    });

                    doc_dash.statusInstagram = statusInstagram;
                    doc_dash.statusFacebook  = statusFacebook;

                    firestore.collection("revenda").doc(doc_dash.idRevenda).collection("vendidos").doc(doc_dash.idVendido)
                    .get()
                    .then(vendido_dash => {

                        if (vendido_dash.exists) {
                            doc_dash.vendido    = vendido_dash.data();
                            doc_dash.vendido.id = vendido_dash.id;

                            let vendidos = this.state.vendidos;
                            vendidos.push(doc_dash);

                            if(this.mounted) {
                                this.setState({
                                    vendidos: vendidos,
                                    countVendidos: vendidos.length
                                });
                            }

                        }else{
                            firestore.collection("dashboard").doc("veiculos").collection("vendidos").doc(doc_dash.id).delete();
                        }

                    }).catch(function(error) {
                        console.log(error);
                    });

                }).catch(function(error) {
                    console.log(error);
                });
                                 
            });

        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    async publicarInstagramStories(idVendido){

        this.setState({modalShow: true});

        var vendido = this.state.vendidos.find(o => o.idVendido === idVendido);
        var vendidoPublicar = vendido.vendido;

        vendidoPublicar.ig_stories = true;
        vendidoPublicar.ig_feed    = false;

        const response = await publicarVendido(vendidoPublicar, "instagram");
        if(response.status === "success"){
            alert("Vendido publicado no stories do instagram.");
            this.setState({modalShow: false});
        }else{
            alert("Erro ao publicar vendido no stories do instagram.");
            this.setState({modalShow: false});
        }
        
    }

    async publicarInstagramFeed(idVendido){

        this.setState({modalShow: true});

        const vendido = this.state.vendidos.find(o => o.idVendido === idVendido);
        var vendidoPublicar = vendido.vendido;

        vendidoPublicar.ig_stories = false;
        vendidoPublicar.ig_feed    = true;

        const response = await publicarVendido(vendidoPublicar, "instagram");
        if(response.status === "success"){
            alert("Vendido publicado no feed do instagram.");
            this.setState({modalShow: false});
        }else{
            alert("Erro ao publicar vendido no feed do instagram.");
            this.setState({modalShow: false});
        }

    }

    async publicarFacebookFeed(idVendido){

        this.setState({modalShow: true});

        const vendido = this.state.vendidos.find(o => o.idVendido === idVendido);
        var vendidoPublicar = vendido.vendido;

        vendidoPublicar.fb_feed = true;

        const response = await publicarVendido(vendidoPublicar, "facebook");
        if(response.status === "success"){
            alert("Vendido publicado no feed do facebook.");
            this.setState({modalShow: false});
        }else{
            alert("Erro ao publicar vendido no feed do facebook.");
            this.setState({modalShow: false});
        }

    }

    baixarVendido = async (idVendido) => {

        this.setState({
            modalShow: true
        });

        var vendido = this.state.vendidos.find(o => o.idVendido === idVendido);
        var vendidoPublicar = vendido.vendido;

        await baixarVendido(vendidoPublicar);

        this.setState({
            modalShow: false
        });

    }

    validaVendido(idVendidoDashboard){
           
        firestore.collection("dashboard").doc("veiculos").collection("vendidos").doc(idVendidoDashboard).delete()
        .then(res => { 
            // alert("Vendido validado com sucesso!");
            window.location.reload();
        })
        .catch(err => { 
            alert("Erro ao validar veículo vendido!");
        });

    }

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Vendidos</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de vendidos - { this.state.countVendidos } veículos
                                </div>
                                <div className="panel-body">
                                    <table width="100%" className="table table-striped table-bordered table-hover" id="dtVeículos">
                                        <thead>
                                            <tr>
                                                <th>Revenda</th>
                                                <th>Imagem</th>
                                                <th>Texto</th>
                                                <th>Data</th>
                                                <th>Ações</th>
                                                <th>Instagram Stories</th>
                                                <th>Instagram Feed</th>
                                                <th>Facebook Feed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(this.state.vendidos)
                                                .map(key => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{ this.state.vendidos[key].logo_revenda !== undefined ? <img src={ this.state.vendidos[key].logo_revenda } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : "" }</td>
                                                            <td>{ this.state.vendidos[key].imagem !== undefined ? <img src={ this.state.vendidos[key].imagem } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : "" }</td>
                                                            <td>{ this.state.vendidos[key].texto !== undefined ? this.state.vendidos[key].texto : "" }</td>
                                                            <td>{ this.state.vendidos[key].created_at !== undefined ? moment(this.state.vendidos[key].created_at.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" }</td>
                                                            <td><a onClick={() => this.validaVendido(this.state.vendidos[key].id)}>Validar</a> / <a onClick={() => this.baixarVendido(this.state.vendidos[key].vendido.id)}>Baixar</a></td>
                                                            <td>
                                                                <table  className="table table-bordered">
                                                                    <thead><tr><th style={{ width: '40px' }}>Publicar</th><th style={{ width: '40px' }}>Status</th><th style={{ width: '40px' }}>Ações</th></tr></thead>
                                                                    <tbody><tr> 
                                                                        { 
                                                                            this.state.vendidos[key].vendido.ig_stories !== undefined && this.state.vendidos[key].vendido.ig_stories === true ?
                                                                                <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                        } 
                                                                        { 
                                                                            this.state.vendidos[key].vendido.valida_stories !== undefined && this.state.vendidos[key].vendido.valida_stories === true ? 
                                                                                <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                        } 
                                                                        { 
                                                                            this.state.vendidos[key].statusInstagram !== undefined && this.state.vendidos[key].statusInstagram === true && (this.state.vendidos[key].vendido.valida_stories === undefined || this.state.vendidos[key].vendido.valida_stories === false) ? 
                                                                                <td><a onClick={() => this.publicarInstagramStories(this.state.vendidos[key].vendido.id)}>Publicar</a></td>
                                                                            : <td></td> 
                                                                        } 
                                                                    </tr></tbody>
                                                                </table>
                                                            </td>
                                                            <td>
                                                                <table  className="table table-bordered">
                                                                    <thead><tr><th style={{ width: '40px' }}>Publicar</th><th style={{ width: '40px' }}>Status</th><th style={{ width: '40px' }}>Ações</th></tr></thead>
                                                                    <tbody><tr> 
                                                                        { 
                                                                            this.state.vendidos[key].vendido.ig_feed !== undefined && this.state.vendidos[key].vendido.ig_feed === true ?
                                                                                <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                        } 
                                                                        { 
                                                                            this.state.vendidos[key].vendido.valida_feed !== undefined && this.state.vendidos[key].vendido.valida_feed === true ? 
                                                                                <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                        } 
                                                                        { 
                                                                            this.state.vendidos[key].statusInstagram !== undefined && this.state.vendidos[key].statusInstagram === true && (this.state.vendidos[key].vendido.valida_feed === undefined || this.state.vendidos[key].vendido.valida_feed === false) ? 
                                                                                <td><a onClick={() => this.publicarInstagramFeed(this.state.vendidos[key].vendido.id)}>Publicar</a></td>
                                                                            : <td></td> 
                                                                        } 
                                                                    </tr></tbody>
                                                                </table> 
                                                            </td>
                                                            <td>
                                                                <table  className="table table-bordered">
                                                                    <thead><tr><th style={{ width: '40px' }}>Publicar</th><th style={{ width: '40px' }}>Status</th><th style={{ width: '40px' }}>Ações</th></tr></thead>
                                                                    <tbody><tr> 
                                                                        { 
                                                                            this.state.vendidos[key].vendido.fb_feed !== undefined && this.state.vendidos[key].vendido.fb_feed === true ?
                                                                                <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                        } 
                                                                        { 
                                                                            this.state.vendidos[key].vendido.fb_valida_feed !== undefined && this.state.vendidos[key].vendido.fb_valida_feed === true ? 
                                                                                <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                        } 
                                                                        { 
                                                                            this.state.vendidos[key].statusFacebook !== undefined && this.state.vendidos[key].statusFacebook === true && (this.state.vendidos[key].vendido.fb_valida_feed === undefined || this.state.vendidos[key].vendido.fb_valida_feed === false) ? 
                                                                                <td><a onClick={() => this.publicarFacebookFeed(this.state.vendidos[key].vendido.id)}>Publicar</a></td>
                                                                            : <td></td> 
                                                                        } 
                                                                    </tr></tbody>
                                                                </table> 
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalShow} 
                    className="modal-class modal-class-aguarde"
                    overlayClassName="modal-overlay"
                    >
                    <div>Aguarde...</div>
                </Modal>
            </div>
            
        )
    }

}

export default DashVendidos